import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Card, CardGrid, Div, Header, Panel, PanelHeader, SimpleCell } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import libermall from '../../img/libermoll.png';
export const Apps = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsxs(Panel, { id: props.id, children: [_jsx(PanelHeader, { className: "delab-fix-header" }), _jsxs(Div, { children: [_jsx(Header, { style: { textAlign: 'center', marginBottom: '12px' }, children: "Apps" }), _jsxs(CardGrid, { size: "l", children: [_jsx(Card, { children: _jsx(SimpleCell, { expandable: true, before: _jsx(Avatar, { mode: 'app', src: 'https://tegro.finance/assets/images/logotype.svg', size: 42 }), href: "https://tegro.finance/swap", target: '_blank', extraSubtitle: "DEX on The Open Network from tegro.money", children: "Tegro.finance" }) }), _jsx(Card, { children: _jsx(SimpleCell, { expandable: true, before: _jsx(Avatar, { mode: 'app', src: libermall, size: 42 }), href: "https://libermall.com/", target: '_blank', extraSubtitle: "Discover, Collect and sell extraordinary NFTs", children: "Libermall" }) })] })] })] }));
};
