/* eslint-disable import/no-extraneous-dependencies */
import { Address, Cell, TonClient, WalletContractV4, comment, internal, toNano } from 'ton';
import { mnemonicNew, mnemonicToPrivateKey } from 'ton-crypto';
import { BOC, Coins, Address as AddressTon3 } from 'ton3';
import * as crypto from 'crypto-js';
import * as ethers from 'ethers';
// import { FEE_TON, wall } from '../consts'
import { TokenWallet } from './jetton';
import { StorageWallet } from './storage';
const client = new TonClient({ endpoint: 'https://mainnet.tonhubapi.com/jsonRPC' });
const globalSecret = '7275737369616e207761727368697020676f206675636b20796f757273656c66';
const workchain = 0; // Usually you need a workchain 0
const fee = toNano('0.006');
export class CustomWallet {
    constructor() {
        this._storage = new StorageWallet();
    }
    static async newWallet() {
        // Generate new key
        const mnemonics = await mnemonicNew();
        console.log(mnemonics);
        const keyPair = await mnemonicToPrivateKey(mnemonics);
        // Create wallet contract
        const wallet = WalletContractV4.create({ workchain, publicKey: keyPair.publicKey });
        // const contract = client.open(wallet)
        // Get balance
        // const balance: bigint = await contract.getBalance()
        console.log(wallet.address);
        // console.log(keyPair.publicKey.toString('hex'))
        return {
            address: wallet.address.toString(),
            publicKey: keyPair.publicKey.toString('hex'),
            secretKey: keyPair.secretKey.toString('hex'),
            mnemonics
        };
    }
    static async getSeqno(publicKey) {
        const wallet = WalletContractV4.create({ workchain, publicKey: Buffer.from(publicKey, 'hex') });
        const contract = client.open(wallet);
        const seqno = await contract.getSeqno();
        return seqno;
    }
    static async balanceTon(publicKey) {
        const wallet = WalletContractV4.create({ workchain, publicKey: Buffer.from(publicKey, 'hex') });
        const contract = client.open(wallet);
        const bal = await contract.getBalance();
        return bal.toString();
    }
    static async sendTon(publicKey, secretKey, trans) {
        const wallet = WalletContractV4.create({ workchain, publicKey: Buffer.from(publicKey, 'hex') });
        const contract = client.open(wallet);
        const seqno = await contract.getSeqno();
        const balance = await contract.getBalance();
        if (balance < toNano('0.001') + fee) {
            return false;
        }
        if (balance < BigInt(trans.value)) {
            return false;
        }
        if (!Address.parse(trans.to).toString()) {
            console.log('error address to address');
            return false;
        }
        console.log('trans', trans);
        // console.log('seqno', seqno)
        // console.log('secretKey', secretKey)
        let comm;
        if (trans.comment)
            comm = comment(trans.comment);
        const body = trans.data ? Cell.fromBase64(trans.data) : undefined;
        const msgs = [internal({
                value: BigInt(trans.value),
                to: trans.to,
                bounce: true,
                body: body ?? comm
            })];
        const tr = contract.createTransfer({
            seqno,
            secretKey: Buffer.from(secretKey, 'hex'),
            sendMode: 1,
            messages: msgs
        });
        try {
            await contract.send(tr);
        }
        catch (err) {
            console.log(err);
            return false;
        }
        return true;
    }
    async signMsg(trans, psw) {
        const loadData = this.loadHashSaveData();
        const privateKey = CustomWallet.HashToKey(psw, loadData.iv, loadData.hash);
        if (!privateKey) {
            return false;
        }
        const wallet = await CustomWallet.getWalletFromMnemonic(privateKey.split(' '));
        const transSend = await CustomWallet.sendTon(wallet.publicKey, wallet.secretKey, {
            to: trans.to.toString({ bounceable: true }),
            value: trans.amount,
            data: trans.data,
            comment: trans.comment
        });
        return transSend;
    }
    async signMsgJetton(trJ, psw, jettonWallet) {
        const loadData = this.loadHashSaveData();
        const dataJ = CustomWallet.sendJettonToBoc(trJ, loadData.address);
        const trToTon = {
            to: Address.parse(jettonWallet),
            amount: new Coins('0.2').toNano(),
            data: dataJ
        };
        const hash = await this.signMsg(trToTon, psw);
        return hash;
    }
    // public static getJettonWalletFromAddress (jettonRoot)
    static async getWalletFromMnemonic(mnemonics) {
        const keyPair = await mnemonicToPrivateKey(mnemonics);
        const wallet = WalletContractV4.create({ workchain, publicKey: keyPair.publicKey });
        return {
            address: wallet.address.toString(),
            publicKey: keyPair.publicKey.toString('hex'),
            secretKey: keyPair.secretKey.toString('hex'),
            mnemonics
        };
    }
    async getPrivateKey(psw) {
        const loadData = this.loadHashSaveData();
        const privateKey = CustomWallet.HashToKey(psw, loadData.iv, loadData.hash);
        if (!privateKey) {
            this._error = { code: 11, text: 'Invalid psw' };
            return this;
        }
    }
    static async KeyToHash(privateKey, psw) {
        console.log('Start encrypt');
        const iv = Buffer.from(ethers.utils.randomBytes(16)).toString('hex');
        const secret = Buffer.from(psw, 'utf8').toString('hex');
        const key = iv + secret + globalSecret;
        console.log('key', key);
        try {
            const hashFromKey = crypto.AES.encrypt(privateKey, key).toString();
            console.log('hashFromKey', hashFromKey);
            return {
                iv: iv.toString(),
                hashFromKey
            };
        }
        catch (error) {
            console.error(error);
            return undefined;
        }
    }
    static HashToKey(psw, iv, hash) {
        console.log('Start decrypt');
        const secret = Buffer.from(psw, 'utf8').toString('hex');
        const key = iv + secret + globalSecret;
        // const key = crypto.scryptSync(secret, iv, 32) // salt
        console.log('secret', secret);
        console.log('key', key);
        try {
            const decyptedData = crypto.AES.decrypt(hash, key).toString(crypto.enc.Utf8);
            console.log('decyptedData', decyptedData);
            return decyptedData;
        }
        catch (error) {
            console.error(error);
            return undefined;
        }
    }
    saveWallet(param) {
        return this._storage.save('iv', param.iv)
            && this._storage.save('hash', param.hashFromKey)
            && this._storage.save('public', param.publicKey)
            && this._storage.save('address', param.address);
    }
    loadHashSaveData() {
        return {
            iv: this._storage.get('iv'),
            hash: this._storage.get('hash'),
            public: this._storage.get('public'),
            address: this._storage.get('address')
        };
    }
    isSaveWallet() {
        return this._storage.get('iv') && this._storage.get('hash');
    }
    async delWallet(psw) {
        const loadData = this.loadHashSaveData();
        const privateKey = await CustomWallet.HashToKey(psw, loadData.iv, loadData.hash);
        if (!privateKey) {
            return false;
        }
        try {
            const wall = await CustomWallet.getWalletFromMnemonic(privateKey.split(' '));
            if (wall.address) {
                this._storage.del('iv');
                this._storage.del('hash');
                this._storage.del('public');
                this._storage.del('address');
                return true;
            }
            return false;
        }
        catch (error) {
            console.error(error);
            return false;
        }
    }
    static sendJettonToBoc(tr, addressUser) {
        const transJetton = {
            queryId: 1,
            tokenAmount: Coins.fromNano(tr.amount),
            fwdAmount: new Coins(0.1),
            to: new AddressTon3(tr.to.toString()),
            responseAddress: new AddressTon3(addressUser.toString())
        };
        const boc = TokenWallet.buildTransferMessage(transJetton);
        const base64 = BOC.toBase64Standard(boc);
        return base64;
    }
}
