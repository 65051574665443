import { jsx as _jsx } from "react/jsx-runtime";
import { PanelHeader } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import logosvg from '../img/logo.svg';
export const HeaderBlock = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [balanceTon, setBalanceTon] = React.useState('0');
    const isDesktop = window.innerWidth >= 1000;
    const location = useLocation();
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    useEffect(() => {
    }, []);
    return (_jsx(PanelHeader, { separator: false, className: "delab-header-full delab-header", children: _jsx("img", { src: logosvg }) }));
};
