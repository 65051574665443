import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CardGrid, ContentCard, Div, Header, Panel, PanelHeader } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { TonApi } from '../../logic/tonapi';
export const Nft = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [nftList, setNftList] = React.useState(undefined);
    const tonapi = new TonApi();
    async function loadNft() {
        const res = await tonapi.searchItemsFromUser(props.addressUser ?? '');
        if (!res) {
            return;
        }
        setNftList(res.nft_items);
    }
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
            loadNft();
        }
    }, []);
    return (_jsxs(Panel, { id: props.id, children: [_jsx(PanelHeader, { className: "delab-fix-header" }), _jsxs(Div, { children: [_jsx(Header, { style: { textAlign: 'center', marginBottom: '12px' }, children: "NFT" }), nftList && nftList.length > 0
                        ? _jsx(CardGrid, { size: "m", children: nftList.map((nft, key) => (_jsx(ContentCard, { onClick: () => { }, src: nft.previews && nft.previews.length > 0 ? nft.previews[1].url : '', subtitle: nft.collection?.name ?? 'Single', header: nft.metadata.name ?? '-', caption: nft.sale ? 'Sale' : 'Not for sale', maxHeight: 150 }, key))) })
                        : _jsx(Div, { style: { textAlign: 'center' }, children: "NFT not found" })] })] }));
};
