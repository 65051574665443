import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon28ArrowDownToSquareOutline, Icon28ShareExternal } from '@vkontakte/icons';
import { Avatar, Card, CardGrid, CellButton, Div, Header, Panel, PanelHeader, SimpleCell } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { Address } from 'ton3';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import axios from 'axios';
import { TonApi } from '../../logic/tonapi';
import { DeLabUtils } from '../../logic/utils';
function rawToTon(address) {
    if (!address) {
        return '';
    }
    const addr = new Address(address, { bounceable: true }).toString('base64');
    return addr;
}
function smlAddr(address) {
    if (!address) {
        return '';
    }
    return address.slice(0, 7) + '...' + address.slice(address.length - 4, address.length);
}
async function getPriceTon() {
    const toncoinData = await axios.get('https://api.coingecko.com/api/v3/coins/the-open-network');
    if (!toncoinData)
        return 0;
    if (!toncoinData.data)
        return 0;
    if (!toncoinData.data.market_data)
        return 0;
    return toncoinData.data.market_data.current_price.usd ?? 0;
}
export const Main = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [price, setPrice] = React.useState(0);
    const [walletInfo, setWalletInfo] = React.useState(undefined);
    const [jettonsInfo, setJettonsInfo] = React.useState(undefined);
    const [transHistory, setTransHistory] = React.useState(undefined);
    const tonapi = new TonApi();
    async function getInfoWallet(address) {
        const res = await tonapi.getInfoUser(address);
        if (!res) {
            return;
        }
        setWalletInfo(res);
        props.setWalletInfo(res);
    }
    async function getJettons(address) {
        const res = await tonapi.getBalances(address);
        if (!res) {
            return;
        }
        setJettonsInfo(res.balances);
        props.setListTokens(res.balances);
    }
    async function getTrans(address) {
        const res = await tonapi.getTransactions(address);
        if (!res) {
            return;
        }
        setTransHistory(res.transactions);
    }
    function packHistory(history) {
        const transPack = [
            [
                history[0]
            ]
        ];
        for (let i = 1; i < history.length; i++) {
            if (transPack.length !== 0) {
                if (transPack[transPack.length - 1][0].utime === history[i].utime) {
                    transPack[transPack.length - 1].push(history[i]);
                }
                else {
                    transPack.push([history[i]]);
                }
            }
            else {
                transPack.push([history[i]]);
            }
        }
        return transPack;
    }
    function checkAndReturnAddress(address1, address2) {
        if (address1 && !address2) {
            let res = smlAddr(rawToTon(address1.address));
            if (address1.name && address1.name !== '')
                res = address1.name;
            return res;
        }
        if (address2 && !address1) {
            let res = smlAddr(rawToTon(address2.address));
            if (address2.name && address2.name !== '')
                res = address2.name;
            return res;
        }
        if (!address1 && !address2) {
            return '';
        }
        if (props.addressUser && address1 && address2) {
            if (rawToTon(address1.address) === props.addressUser) {
                let res = smlAddr(rawToTon(address2.address));
                if (address2.name && address2.name !== '')
                    res = address2.name;
                return res;
            }
            let res = smlAddr(rawToTon(address1.address));
            if (address1.name && address1.name !== '')
                res = address1.name;
            return res;
        }
        if (!props.addressUser && address1 && address2) {
            let res = smlAddr(rawToTon(address1.address));
            if (address1.name && address1.name !== '')
                res = address1.name;
            return res;
        }
        return '';
    }
    function base64ToUtf(text) {
        if (!text) {
            return undefined;
        }
        if (text === '') {
            return undefined;
        }
        const newText = Buffer.from(text, 'base64').toString('utf-8');
        if (newText.indexOf('�') !== -1
            || newText.indexOf('�') !== -1) {
            return undefined;
        }
        return newText;
    }
    function chAU(address1) {
        if (!address1)
            return false;
        return rawToTon(address1.address) === props.addressUser;
    }
    function openModalTrans(tr, tr2) {
        if (!tr) {
            return;
        }
        props.setTransModalFull(tr2);
        props.setTransModal(tr);
        props.setActiveModal('transaction');
    }
    useEffect(() => {
        if (!firstRender && props.addressUser) {
            setFirstRender(true);
            getInfoWallet(props.addressUser);
            getJettons(props.addressUser);
            getTrans(props.addressUser);
            getPriceTon().then((data) => {
                setPrice(data);
            });
            // injectTon()
        }
    }, [props.addressUser]);
    return (_jsxs(Panel, { id: props.id, children: [_jsx(PanelHeader, { className: "delab-fix-header" }), props.addressUser ? _jsxs("div", { children: [_jsx(Div, { children: _jsx(Header, { children: "Wallet" }) }), _jsxs(Div, { children: [_jsx(CardGrid, { size: "l", children: _jsx(Card, { children: _jsxs(Div, { style: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }, children: [_jsx(SimpleCell, { subtitle: '$' + (price * Number(DeLabUtils.fromNanoToString(walletInfo?.balance ?? '0'))).toFixed(2), children: _jsxs("b", { children: [DeLabUtils.fromNanoToString(walletInfo?.balance ?? '0'), " TON"] }) }), _jsx(SimpleCell, { subtitle: walletInfo?.name, onClick: () => {
                                                    copy(props.addressUser ?? '');
                                                    props.consoleLog('Copyed', true);
                                                }, children: _jsx("b", { children: smlAddr(props.addressUser) }) })] }) }) }), _jsxs(CardGrid, { size: "m", children: [_jsx(Card, { children: _jsx(CellButton, { centered: true, before: _jsx(Icon28ArrowDownToSquareOutline, {}), onClick: () => props.setActiveModal('recive'), children: _jsx("b", { children: "Recive" }) }) }), _jsx(Card, { children: _jsx(CellButton, { centered: true, before: _jsx(Icon28ShareExternal, {}), onClick: () => props.setActiveModal('send_address'), children: _jsx("b", { children: "Send" }) }) })] })] }), jettonsInfo && jettonsInfo.length > 0
                        ? _jsx(Div, { children: _jsx(CardGrid, { size: "l", children: jettonsInfo.filter(j => j.metadata).filter(j => BigInt(j.balance) > 0).map((jetton, key) => (_jsx(Card, { children: _jsx(SimpleCell, { before: _jsx(Avatar, { src: jetton.metadata?.image ?? '', size: 48 }), subtitle: `${DeLabUtils.fromNanoToString(jetton.balance)} ${jetton.metadata?.symbol}`, children: jetton.metadata?.name }) }, key))) }) })
                        : null, _jsx(Div, { children: _jsx(Header, { children: "History" }) }), transHistory && transHistory.length > 0 ? _jsx(Div, { children: _jsx(CardGrid, { size: "l", className: "history", children: packHistory(transHistory).map((trans, key) => (_jsx(Card, { children: trans.map((tr, key3) => (_jsxs("span", { children: [tr.in_msg && tr.in_msg.value > 0
                                            ? _jsx(SimpleCell, { expandable: true, multiline: true, className: chAU(tr.in_msg?.destination) ? 'plus1' : '', after: rawToTon(tr.in_msg?.destination?.address) === props.addressUser
                                                    ? `+ ${DeLabUtils.fromNanoToString(tr.in_msg.value)} TON`
                                                    : `- ${DeLabUtils.fromNanoToString(tr.in_msg.value)} TON`, subtitle: _jsxs("span", { children: [checkAndReturnAddress(tr.in_msg?.source, tr.in_msg?.destination), base64ToUtf(tr.in_msg.msg_data)
                                                            ? _jsx("span", { className: "msg_text", children: base64ToUtf(tr.in_msg.msg_data) })
                                                            : null] }), onClick: () => openModalTrans(tr.in_msg, tr), before: rawToTon(tr.in_msg?.destination?.address) === props.addressUser
                                                    ? _jsx(Icon28ArrowDownToSquareOutline, {})
                                                    : _jsx(Icon28ShareExternal, {}), extraSubtitle: moment(tr.utime * 1000).format('MMM DD, HH:MM'), children: rawToTon(tr.in_msg?.destination?.address) === props.addressUser ? 'Recived' : 'Sended' })
                                            : null, tr.out_msgs.map((out, key2) => (_jsx(SimpleCell, { expandable: true, multiline: true, onClick: () => openModalTrans(out, tr), className: chAU(out?.destination) ? 'plus1' : '', after: rawToTon(out?.destination?.address) === props.addressUser
                                                ? `+ ${DeLabUtils.fromNanoToString(out.value)} TON`
                                                : `- ${DeLabUtils.fromNanoToString(out.value)} TON`, subtitle: _jsxs("span", { children: [checkAndReturnAddress(out?.source, out?.destination), base64ToUtf(out.msg_data)
                                                        ? _jsx("span", { className: "msg_text", children: base64ToUtf(out.msg_data) })
                                                        : null] }), before: rawToTon(out?.destination?.address) === props.addressUser
                                                ? _jsx(Icon28ArrowDownToSquareOutline, {})
                                                : _jsx(Icon28ShareExternal, {}), extraSubtitle: moment(tr.utime * 1000).format('MMM DD, HH:MM'), children: rawToTon(out?.destination?.address) === props.addressUser ? 'Recived' : 'Sended' }, key2)))] }, key3))) }, key))) }) }) : _jsx(Div, { style: { textAlign: 'center' }, children: "History is empty" })] }) : null] }));
};
