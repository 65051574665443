import { Address } from 'ton';
export class CustomEventApi {
    constructor(address, publicKey, balance, cb) {
        this._accounts = [address];
        this._wallets = [{
                address,
                publicKey,
                walletVersion: 'v4'
            }];
        this._walletTonConnect = {
            name: 'ton_addr',
            address: Address.parse(address).toRawString(),
            network: 'mainnet',
            walletStateInit: ''
        };
        this._balance = balance;
        this._cbSend = cb;
        if (typeof window.chrome === 'undefined') {
            if (window.chrome && chrome.runtime && chrome.runtime.onMessageExternal) {
                chrome.runtime.onMessageExternal.addListener(async (request, sender, sendResponse) => {
                    if (request.type === 'deWallet_api') {
                        switch (request.message.method) {
                            case 'ton_requestAccounts':
                                sendResponse(CustomEventApi.packData(this._accounts));
                                break;
                            case 'ton_requestWallets':
                                sendResponse(CustomEventApi.packData(this._wallets));
                                break;
                            case 'ton_getBalance':
                                sendResponse(CustomEventApi.packData(this._balance));
                                break;
                            case 'ton_sendTransaction':
                                const res = await this._cbSend(request.message.params);
                                sendResponse(CustomEventApi.packData(res));
                                break;
                            case 'tonConnect_connect':
                                sendResponse(CustomEventApi.packData(this._walletTonConnect));
                                break;
                            default:
                                sendResponse({ status: 'error', text: 'method not found' });
                                break;
                        }
                    }
                    else {
                        sendResponse({ status: 'error' });
                    }
                    if (request?.message?.method !== 'ton_sendTransaction') {
                        const rurrentWindow = await chrome.windows.getCurrent();
                        if (rurrentWindow.id)
                            chrome.windows.remove(rurrentWindow.id);
                    }
                });
            }
        }
    }
    static packData(params, status = 'ok') {
        return { status, type: 'deWallet_api_callback', params };
    }
    updateBalance(balance) {
        this._balance = balance;
    }
}
